<template>
    <section>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3">
                <div class="table-box p-3">
                    <div class="border-bottom pb-3">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <router-link v-if="data.permission.canCreate"
                                             to="/library/external-documents/create"
                                             class="btn btn-primary">
                                    Create New
                                </router-link>
                            </div>
                            <div class="expand-ctl">
                                <p>
                                    <button class="btn btn-primary" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseProject-ctl" aria-expanded="false"
                                            aria-controls="collapseProject-ctl">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor"
                                             class="bi bi-funnel-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/>
                                        </svg>
                                    </button>
                                </p>
                            </div>
                        </div>
                        <div class="collapse mt-3" id="collapseProject-ctl">
                            <table-filter @change="getFilteredValue"/>
                        </div>
                    </div>
                    <data-table :options="dataList.options"
                                :data-set="data.dataList"
                                :page-size="data.pageSize"
                                @change-action="changeAction"
                                @pagination="pagination"
                                @limit-change="limitChange"/>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import TableFilter from "@/common/components/table-filter/TableFilter";
import {useRoute, useRouter} from "vue-router";
import {onMounted, reactive, inject} from "vue";
import DataTable from "@/common/components/datatable";
import HelperFunction from "@/common/helpers";
import {useStore} from "vuex";

export default {
    name: "ExternalDocuments",
    components: {TableFilter, DataTable},
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const swal = inject('$swal')
        const {
            data,
            getAllData,
            selectedCompany,
            deleteListData,
            permissionResolver
        } = HelperFunction();

        /** watch **/
        permissionResolver(route.path);

        let dataList = reactive({
            isModalActive: false,
            params: {
                page: null,
                limit: null,
                sort: 'desc',
                search: '',
                company_id: null,
            },
            formError: {},
            formData: {},
            options: {
                name: 'External Documents',
                columns: [
                    {
                        title: 'Document Id',
                        type: 'text',
                        key: 'document_id',
                        tooltip: false
                    },
                    {
                        title: "Title",
                        type: "text",
                        key: "title",
                        tooltip: true,
                    },
                    {
                        title: 'Version',
                        type: 'text',
                        key: 'document_version',
                        tooltip: false
                    },
                    {
                        title: 'Effective date',
                        type: 'text',
                        key: 'effective_date',
                        tooltip: false
                    },
                    {
                        title: 'Status',
                        type: 'text',
                        key: 'status',
                        tooltip: true
                    },
                    {
                        title: "Status",
                        type: "html",
                        key: "status",
                        tooltip: true,
                        modifier: (column, row) => {
                            if (column === "Completed") {
                                return `<div class="btn btn-light-green rounded-pill">${column}</div>`;
                            }
                            return `<div class="btn btn-pink rounded-pill">${column}</div>`;
                        },
                    },
                ],
                showAction: true,
                action: [
                    {
                        title: "History",
                        icon: "history",
                        type: "history",
                        show: true,
                        modifier: (action, row) => {
                            return row.document_version > 1;
                        },
                    },
                    {
                        title: "Re Open",
                        icon: "lock",
                        type: "re-open",
                        show: data.permission.canEdit,
                        modifier: (action, row) => {
                            return row.status === 'Completed';
                        },
                    },
                    {
                        title: 'Edit',
                        icon: 'edit',
                        type: 'edit',
                        show: data.permission.canEdit,
                        modifier: (action, row) => {
                            return row.status === 'Created';
                        },
                    },
                    {
                        title: 'Delete',
                        icon: 'trash-alt',
                        type: 'delete',
                        show: data.permission.canDelete,
                        modifier: (action, row) => {
                            return row.status === 'Created';
                        },
                    }
                ]
            }
        })

        /** mounted**/
        onMounted(() => {
            getData();
        })
        /** mounted end**/
        const getData = () => {
            data.setURL = vueConfig.Library.ExternalDocumentsEndPoint;
            dataList.params.company_id = selectedCompany.value.id ? selectedCompany.value.id : null;
            getAllData(data.setURL, dataList.params);
        }

        const changeAction = (action, row) => {
            if (action.type === 'edit') {
                router.push(`/library/external-documents/${row.id}`)
            } else if (action.type === 'delete') {
                deleteListData(`${vueConfig.Library.ExternalDocumentsEndPoint}/${row.id}`)
                    .then(() => {
                        getData()
                    });
            } else if (action.type === 're-open') {
                reOpen(row);
            } else if (action.type === 'history') {
                router.push(`/library/external-documents/history/${row.id}`)
            }
        }

        function reOpen(row) {
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, reopen it!'
            }).then(async (result) => {
                if (result.isConfirmed === true) {
                    let payload = {
                        action: row.id,
                        formData: row
                    }
                    store.dispatch('reOpenExternalDocument', payload)
                        .then(res => {
                            getData();
                        })
                }
            })
        }

        const pagination = (page) => {
            dataList.params.page = page;
            getData();
        }
        const limitChange = (limit) => {
            dataList.params.limit = limit;
            delete dataList.params.page
            getData();
        }

        function getFilteredValue(params) {
            console.log(params);
        }

        return {
            data,
            pagination,
            limitChange,
            changeAction,
            dataList,
            getFilteredValue
        }
    }
}
</script>
